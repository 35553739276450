import { motion } from 'framer-motion';

const About = () => {
  return (
    <section id="about" className="section bg-gray-50">
      <div className="container">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-12 items-center">
          {/* Image Section */}
          <motion.div
            initial={{ opacity: 0, x: -50 }}
            whileInView={{ opacity: 1, x: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.8 }}
            className="relative"
          >
            <div className="aspect-w-4 aspect-h-3">
              <img
                src="/images/about.jpg"
                alt="About Faskum Technologies"
                className="object-cover rounded-lg shadow-xl"
              />
            </div>
            <div className="absolute -bottom-6 -right-6 bg-primary text-white p-6 rounded-lg shadow-lg">
              <h3 className="text-3xl font-bold">10+</h3>
              <p className="text-sm">Years of Experience</p>
            </div>
          </motion.div>

          {/* Content Section */}
          <motion.div
            initial={{ opacity: 0, x: 50 }}
            whileInView={{ opacity: 1, x: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.8 }}
          >
            <h2 className="text-3xl md:text-4xl font-bold mb-6">
            Faskum Design Excellence Since 2014
            </h2>
            <p className="text-gray-600 mb-6">
              We are a team of passionate designers and developers committed to creating 
              exceptional digital experiences. Our approach combines creativity with 
              technical expertise to deliver solutions that not only look stunning but 
              also drive real business results.
            </p>
            <div className="grid grid-cols-2 gap-6 mb-8">
              <div>
                <h4 className="text-xl font-bold text-primary mb-2">250+</h4>
                <p className="text-gray-600">Projects Completed</p>
              </div>
              <div>
                <h4 className="text-xl font-bold text-primary mb-2">180+</h4>
                <p className="text-gray-600">Happy Clients</p>
              </div>
              <div>
                <h4 className="text-xl font-bold text-primary mb-2">15+</h4>
                <p className="text-gray-600">Team Members</p>
              </div>
              <div>
                <h4 className="text-xl font-bold text-primary mb-2">99%</h4>
                <p className="text-gray-600">Client Satisfaction</p>
              </div>
            </div>
            <motion.a
              href="#contact"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className="btn btn-primary inline-block"
            >
              Let's Work Together
            </motion.a>
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default About;
