import { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

const projects = [
  {
    id: 1,
    title: 'E-Commerce Platform',
    category: 'Web Development',
    image: '/images/projects/ecommerce.jpg',
    description: 'Modern e-commerce platform with seamless user experience and advanced features.',
  },
  {
    id: 2,
    title: 'Financial App UI',
    category: 'UI/UX Design',
    image: '/images/projects/finance.jpg',
    description: 'Intuitive mobile banking application interface with focus on user security.',
  },
  {
    id: 3,
    title: 'Travel Companion App',
    category: 'Mobile Apps',
    image: '/images/projects/travel.jpg',
    description: 'Cross-platform travel app with real-time updates and interactive maps.',
  },
  {
    id: 4,
    title: 'Corporate Branding',
    category: 'Brand Strategy',
    image: '/images/projects/branding.jpg',
    description: 'Complete brand identity redesign for a tech startup.',
  },
  {
    id: 5,
    title: 'Product Animation',
    category: 'Motion Design',
    image: '/images/projects/animation.jpg',
    description: 'Engaging product showcase animations for digital marketing.',
  },
  {
    id: 6,
    title: 'Marketing Campaign',
    category: 'Digital Marketing',
    image: '/images/projects/marketing.jpg',
    description: 'Successful digital marketing campaign with impressive ROI.',
  },
];

const categories = ['All', 'Web Development', 'UI/UX Design', 'Mobile Apps', 'Brand Strategy', 'Motion Design', 'Digital Marketing'];

const Projects = () => {
  const [activeCategory, setActiveCategory] = useState('All');

  const filteredProjects = projects.filter(
    project => activeCategory === 'All' || project.category === activeCategory
  );

  return (
    <section id="projects" className="section bg-gray-50">
      <div className="container">
        <div className="text-center max-w-3xl mx-auto mb-16">
          <motion.h2
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5 }}
            className="text-3xl md:text-4xl font-bold mb-6"
          >
            Our Projects
          </motion.h2>
          <motion.p
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5, delay: 0.2 }}
            className="text-gray-600"
          >
            Explore our portfolio of successful projects that showcase our expertise
            and creativity across different domains.
          </motion.p>
        </div>

        {/* Categories */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.5, delay: 0.4 }}
          className="flex flex-wrap justify-center gap-4 mb-12"
        >
          {categories.map((category) => (
            <button
              key={category}
              onClick={() => setActiveCategory(category)}
              className={`px-6 py-2 rounded-full transition-all ${
                activeCategory === category
                  ? 'bg-primary text-white'
                  : 'bg-white text-gray-600 hover:bg-gray-100'
              }`}
            >
              {category}
            </button>
          ))}
        </motion.div>

        {/* Projects Grid */}
        <AnimatePresence mode="wait">
          <motion.div
            key={activeCategory}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.5 }}
            className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8"
          >
            {filteredProjects.map((project) => (
              <motion.div
                key={project.id}
                layout
                className="bg-white rounded-lg overflow-hidden shadow-lg hover:shadow-xl transition-shadow"
              >
                <div className="relative aspect-w-16 aspect-h-9">
                  <img
                    src={project.image}
                    alt={project.title}
                    className="absolute inset-0 w-full h-full object-cover"
                  />
                </div>
                <div className="p-6">
                  <h3 className="text-xl font-bold mb-2">{project.title}</h3>
                  <p className="text-gray-600 mb-4">{project.description}</p>
                  <span className="inline-block px-3 py-1 bg-gray-100 text-gray-600 rounded-full text-sm">
                    {project.category}
                  </span>
                </div>
              </motion.div>
            ))}
          </motion.div>
        </AnimatePresence>
      </div>
    </section>
  );
};

export default Projects;
