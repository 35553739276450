import { motion } from 'framer-motion';

const services = [
  {
    icon: '🎨',
    title: 'UI/UX Design',
    description: 'Creating intuitive and engaging user experiences through thoughtful design and interaction.',
  },
  {
    icon: '💻',
    title: 'Web Development',
    description: 'Building responsive and performant websites using cutting-edge technologies.',
  },
  {
    icon: '📱',
    title: 'Mobile Apps',
    description: 'Developing native and cross-platform mobile applications for iOS and Android.',
  },
  {
    icon: '🎯',
    title: 'Digital Marketing',
    description: 'Driving growth through strategic digital marketing campaigns and SEO optimization.',
  },
  {
    icon: '🎥',
    title: 'Motion Design',
    description: 'Creating engaging animations and motion graphics for digital platforms.',
  },
  {
    icon: '🔍',
    title: 'Brand Strategy',
    description: 'Developing comprehensive brand strategies to help businesses stand out.',
  },
];

const containerVariants = {
  hidden: {},
  visible: {
    transition: {
      staggerChildren: 0.2,
    },
  },
};

const itemVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.5,
    },
  },
};

const Services = () => {
  return (
    <section id="services" className="section">
      <div className="container">
        <div className="text-center max-w-3xl mx-auto mb-16">
          <motion.h2
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5 }}
            className="text-3xl md:text-4xl font-bold mb-6"
          >
            Our Services
          </motion.h2>
          <motion.p
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5, delay: 0.2 }}
            className="text-gray-600"
          >
            We offer a comprehensive range of digital services to help your business
            thrive in the modern world.
          </motion.p>
        </div>

        <motion.div
          variants={containerVariants}
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8"
        >
          {services.map((service, index) => (
            <motion.div
              key={index}
              variants={itemVariants}
              className="bg-white p-8 rounded-lg shadow-lg hover:shadow-xl transition-shadow"
            >
              <div className="text-4xl mb-4">{service.icon}</div>
              <h3 className="text-xl font-bold mb-4">{service.title}</h3>
              <p className="text-gray-600">{service.description}</p>
            </motion.div>
          ))}
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.5, delay: 0.6 }}
          className="text-center mt-12"
        >
          <a href="#contact" className="btn btn-primary">
            Discuss Your Project
          </a>
        </motion.div>
      </div>
    </section>
  );
};

export default Services;
